import React, { useEffect, useState } from "react";
import MyList from "../component/MyList";
import GoBack from "../component/GoBack";

//import { Initiate } from "./suite/search_cmn";
import { MufradaInput } from "./suite/search_cmn";
import { SearchSelector } from "./suite/search_cmn";
import { SearchButton } from "./suite/search_cmn";
import { DisplayAyatMono } from "./suite/search_cmn";
import { WordCategories } from "./suite/search_cmn";

import "../styles/mystyle.css";
import { useMyContext } from "../component/MyContext";

function SearchMono() {
/*   const [huruf, huruf_set] = useState({});
  const [aalam, aalam_set] = useState({});
  const [judur, judur_set] = useState({}); */
const {huruf, aalam, judur} = useMyContext()

  const [srch, srch_set] = useState("2");
  const [word, word_set] = useState("رحمة");
  const [klmt, klmt_set] = useState([]);
  const [ayat, ayat_set] = useState({});

  const [ayat_display, ayat_display_set] = useState("");
  const [klmt_display, klmt_display_set] = useState("");

  //useEffect(() => { Initiate({ huruf_set, aalam_set, judur_set }); }, []);
  useEffect(() => { ayat_display_set(<DisplayAyatMono ayat={ayat} kalimat={klmt} />); }, [ayat, klmt]);
  useEffect(() => { klmt_display_set(<MyList data={klmt} />); }, [klmt]);

  return (
    <div className="col" style={{ fontSize: 14 }}>
      <div className="row">
        <GoBack style={{ fontSize: "10px" }} />
        <label style={{ fontSize: "20px" }}> بــحــث </label>
      </div>
      <hr />
      <WordCategories huruf={huruf} aalam={aalam} judur={judur} word_set={word_set} />
      <hr />
      <MufradaInput title="المفردة" text={word} text_set={word_set} />
      <SearchSelector name="choice" choice={srch} choice_set={srch_set} />
      <hr />
      <SearchButton title={"بـــحـــث"}
        task={201} word1={word} srch1={srch} ayat_set={ayat_set} klmt1_set={klmt_set} />
      <hr />
      <label> عدد الكلمات {klmt.length} </label>
      {klmt_display}
      <hr />
      <label> عدد الآيات {ayat.length} </label>
      {ayat_display}
    </div>
  );
}

export default SearchMono;
