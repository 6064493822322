import Cookies from 'js-cookie';

export function load_cookie(name,def) {
  //console.log("load cookie:",name);
  var ck;
  ck = Cookies.get(name);
  if (ck === undefined) ck = def;
  return ck;
}

export function save_cookie(name,value) {
  console.log("save cookie:",name);
  Cookies.set(name, value, { expires: 1000 });
}

export function remove_cookie (name) {
  Cookies.remove(name);
}