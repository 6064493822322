import { useNavigate } from "react-router-dom";
function GoBack() {
  const navigate = useNavigate();
  async function handleClick() {
    navigate(-1);
    //navigate("/home");
  }
  return (
    <div>
      <button onClick={handleClick} style={{ border: "none", background: "transparent", cursor: "pointer" }}>
        <img src="return.png" alt="icon" />
      </button>
    </div>
  );
};
export default GoBack