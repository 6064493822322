import React, { useEffect, useState } from "react";
import MyList from "../component/MyList";
import GoBack from "../component/GoBack";

import { Initiate as initiate } from "./suite/search_cmn";
import { MufradaInput } from "./suite/search_cmn";
import { SearchSelector } from "./suite/search_cmn";
import { SearchButton } from "./suite/search_cmn";
import { DisplayAyatBino } from "./suite/search_cmn";
import { WordCategories } from "./suite/search_cmn";

import "../styles/mystyle.css";

function SearchBino() {
  const [huruf, huruf_set] = useState({});
  const [aalam, aalam_set] = useState({});
  const [judur, judur_set] = useState({});

  const [word1, word1_set] = useState("رحمة");
  const [word2, word2_set] = useState("حكمة");

  const [srch1, srch1_set] = useState("2");
  const [srch2, srch2_set] = useState("2");

  const [klmt1, klmt1_set] = useState([]);
  const [klmt2, klmt2_set] = useState([]);
  const [ayat , ayat_set ] = useState({});



  const [ayat_display , ayat_display_set ] = useState("");
  const [klmt1_display, klmt1_display_set] = useState("");
  const [klmt2_display, klmt2_display_set] = useState("");

  useEffect(() => { initiate({ huruf_set, aalam_set, judur_set }); }, []);
  useEffect(() => { ayat_display_set(<DisplayAyatBino ayat={ayat} klmt1={klmt1} klmt2={klmt2} />); }, [ayat, klmt1,klmt2]);
  useEffect(() => { klmt1_display_set(<MyList data={klmt1} />); }, [klmt1]);
  useEffect(() => { klmt2_display_set(<MyList data={klmt2} />); }, [klmt2]);

  return (
    <div className="col" style={{ fontSize: 14 }}>
      <div className="row">
        <GoBack style={{ fontSize: "10px" }} />
        <label style={{ fontSize: "20px" }}> بــحــث </label>
      </div>
      <hr />
      <div className="row">
        <WordCategories huruf={huruf} aalam={aalam} judur={judur} word_set={word1_set} />
        <WordCategories huruf={huruf} aalam={aalam} judur={judur} word_set={word2_set} />
      </div>
      <hr />
      <div className="row">
        <MufradaInput title=" المفردة الأولى"   text={word1} text_set={word1_set} />
        <MufradaInput title=" المفردة الثانية" text={word2} text_set={word2_set} />
      </div>
      <div className="row">
        <SearchSelector name="choice1" choice={srch1} choice_set={srch1_set} />
        <SearchSelector name="choice2" choice={srch2} choice_set={srch2_set} />
      </div>
      <hr />
      <SearchButton title={" بـــحـــث عن الأولى أو الثانية "}
        task={202} word1={word1} srch1={srch1} word2={word2} srch2={srch2} ayat_set={ayat_set} klmt1_set={klmt1_set} klmt2_set={klmt2_set} />
      <hr />
      <SearchButton title={" بـــحـــث  عن الأولى و الثانية "}
        task={203} word1={word1} srch1={srch1} word2={word2} srch2={srch2} ayat_set={ayat_set} klmt1_set={klmt1_set} klmt2_set={klmt2_set} />
      <hr />
      <SearchButton title={" بـــحـــث  عن الأولى دون الثانية "}
        task={204} word1={word1} srch1={srch1} word2={word2} srch2={srch2} ayat_set={ayat_set} klmt1_set={klmt1_set} klmt2_set={klmt2_set} />
      <hr />
      <label> عدد الكلمات {klmt1.length} </label>
      {klmt1_display}
      <hr />
      <label> عدد الكلمات {klmt2.length} </label>
      {klmt2_display}
      <hr />
      <label> عدد الآيات {ayat.length} </label>
      {ayat_display}
    </div>
  );
}

export default SearchBino;
