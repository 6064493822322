import React, { useState, useEffect } from 'react'
import { useMyContext } from '../component/MyContext'
import { RiwayaSelector, FontSelector, get_font } from './suite/RiwayaFont'
import { request_explor } from '../common/web'
import GoBack from '../component/GoBack'
import Sourat from '../common/Sourat'
import '../styles/mystyle.css'
import TableFromJasonFor from '../component/TableFromJasonFor'
import { save_cookie } from '../common/cookies'


function ExplorSura() {
  const { rwya, rwya_set, sura, sura_set, aaya, aaya_set } = useMyContext();
  const [page, page_set] = useState("");
  const [ayat, ayat_set] = useState([]);
  const [font, font_set] = useState(rwya);

  useEffect(
    () => {
      //console.log("font:",get_font(font));
      display_page(ayat, font);
    }
    , [ayat, font]
  );

  useEffect(
    () => {
      save_cookie("rwya", rwya);
      save_cookie("sura", sura);
      save_cookie("aaya", aaya);
      request_section(rwya, sura, aaya);
    }
    , [rwya, sura, aaya]
  );

  function display_page(ayat, font) {
    const head = ['صفحة', 'آية', 'نص الآيــة'];
    page_set(<TableFromJasonFor head={head} data={ayat} />)
  }

  async function request_section(rwya, sura, aaya) {
    var data, error;
    const query = { "code": 101, "rwya": Number(rwya), "sura": sura, "aaya": aaya };
    [data, error] = await request_explor(query);
    if (error !== "") {
      alert(error);
      return;
    }
    ayat_set(data);
  }

  function NextSection() {
    async function handleClick(e) {
      var data, error;
      const query = { "code": 102, "sura": sura, "aaya": aaya };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sura_set(data.sura);
      aaya_set(data.aaya);
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevSection() {
    async function handleClick(e) {
      var data, error;
      const query = { "code": 103, "sura": sura, "aaya": aaya };
      [data, error] = await request_explor(query);
      if (error !== "") {
        alert(error);
        return;
      }
      sura_set(data.sura);
      aaya_set(data.aaya);
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function DisplayPage() {
    return (
      <div style={{ fontFamily: get_font(font) }}>
        {page}
      </div>
    )
  }

  const [surat_visible, surat_visible_set] = useState(false);
  const surat_show = () => surat_visible_set(true);
  const surat_hide = () => {
    surat_visible_set(false);
    aaya_set(1);
  }
  function GetSurat() {
    return (<div> <button onClick={surat_show}> {sura} </button> </div>);
  }

  return (
    <>
      <Sourat show={surat_visible} onClose={surat_hide}> </Sourat>
      <div className='col'>
        <label> ( تـــصــفـــح الســــور  ) </label> 
        <div className='row'>
          <GoBack />
          <label>&nbsp;&nbsp;&nbsp; الســـورة </label>
          <GetSurat /> &nbsp;&nbsp;&nbsp;
          <RiwayaSelector selectedRiwaya={rwya} setSelectedRiwaya={rwya_set} setSelectedFont={font_set} />
          <FontSelector show={false} selectedFont={font} setSelectedFont={font_set} />
          &nbsp;&nbsp;&nbsp;<PrevSection />
          <NextSection />
        </div>
        <hr></hr>
        <DisplayPage />
        <div className='row'>
          <PrevSection />
          <NextSection />
        </div>
      </div>
    </>
  )
}

export default ExplorSura