import React from 'react';
import '../styles/mystyle.css'

const MyList = ({ data }) => {
   const list = [];
   for (let i = 0; i < data.length; i++) {
      list.push( <div key={i}> {data[i]} </div> );
   }
   //console.log("list",list)
   return ( <div className='row'> {list} </div> );
};

export default MyList;
