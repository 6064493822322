import React, { useState } from "react";
import { request_search } from "../../common/web";
//import { SearchItem } from "../../common/mytools";
import { useNavigate } from "react-router-dom";

export async function Initiate({ huruf_set, aalam_set, judur_set }) {
   console.log("Initiate");
   var data, error;
   [data, error] = await request_search({ task: 101 });
   huruf_set(data);
   if (error !== "") {
      alert(error);
      return;
   }
   [data, error] = await request_search({ task: 102 });
   aalam_set(data);
   if (error !== "") {
      alert(error);
      return;
   }
   [data, error] = await request_search({ task: 103 });
   judur_set(data);
   if (error !== "") {
      alert(error);
      return;
   }
}

function WordCategory({ title, list, on_changed_option }) {
   const [selectedOption, setSelectedOption] = useState("");
   const handleChange = (event) => {
      setSelectedOption(event.target.value);
      on_changed_option(event.target.value);
   };

   const renderOptions = () => {
      let options = [];
      options.push(<option key={-1} value={""}> {title} </option>);
      for (let i = 0; i < list.length; i++) {
         options.push(
            <option key={list[i].key} value={list[i].value}> {list[i].value} </option>
         );
      }
      return options;
   };
   return (
      <div className="p-4">
         <select value={selectedOption} onChange={handleChange} >
            <option value="">{title}</option>
            {renderOptions()}
         </select>
      </div>
   );
};

export function WordCategories({ huruf, aalam, judur, word_set }) {
   return (
      <div className="row">
         <WordCategory title="حروف" list={huruf} on_changed_option={word_set} />
         <WordCategory title="أعلام" list={aalam} on_changed_option={word_set} />
         <WordCategory title="جذور" list={judur} on_changed_option={word_set} />
      </div>
   );
}


export function MufradaInput({ title, text, text_set }) {
   const handleChange = (event) => {
      text_set(event.target.value);
   };

   return (
      <div className="p-4">
         <label> {title} </label>
         <input type="text" value={text} onChange={handleChange} style={{ width: 50 }} />
      </div>
   );
};

export function SearchSelector({ name, choice, choice_set }) {
   const handleChange = (event) => {
      choice_set(event.target.value);
   };
   const opt = [
      "البحث عن المفردة باعتبارها كلمة مستقلة",
      "البحث عن المفردة باعتبارها جزء من كلمة",
      "البحث عن المفردة باعتبارها جذرا",
   ];

   return (
      <div>
         <div>
            <input type="radio" name={name} value="1" checked={choice === "1"} onChange={handleChange} />
            {opt[0]}
         </div>
         <div>
            <input type="radio" name={name} value="2" checked={choice === "2"} onChange={handleChange} />
            {opt[1]}
         </div>
         <div>
            <input type="radio" name={name} value="3" checked={choice === "3"} onChange={handleChange} />
            {opt[2]}
         </div>
      </div>
   );
};

export function SearchButton({ title, task, word1, srch1, word2, srch2, ayat_set, klmt1_set, klmt2_set }) {
   async function handleClick() {
      var ayat, klmt1, klmt2, error;
      switch (task) {
         case 201:
            [ayat, error] = await request_search({ task: task, word1: word1, srch1: srch1 });
            [klmt1, error] = await request_search({ task: 301, word1: word1, srch1: srch1 });
            if (error !== "") { alert(error); return; }
            ayat_set(ayat);
            klmt1_set(klmt1);
            break;
         case 202: case 203: case 204:
            [ayat, error] = await request_search({ task: task, word1: word1, srch1: srch1, word2: word2, srch2: srch2 });
            [klmt1, error] = await request_search({ task: 301, word1: word1, srch1: srch1 });
            [klmt2, error] = await request_search({ task: 301, word1: word2, srch1: srch2 });
            if (error !== "") { alert(error); return; }
            ayat_set(ayat);
            klmt1_set(klmt1);
            klmt2_set(klmt2);
            break;
         default:
            break;
      }
   };

   return (
      <button onClick={handleClick} > {title} </button>
   );
};

function FindContext({ ayaNo }) {
   const navigate = useNavigate();
   async function handleClick() {
      //navigate("/search_context",{state: {userId:123,username:"Alice"}});
      navigate("/search_context", { state: { ayaId: ayaNo } });
   }
   return (
      <div>
         <button onClick={handleClick} > context </button>
      </div>
   );
};

export function DisplayAyatMono({ ayat, kalimat }) {
   const [selectedRow, setSelectedRow] = useState(null);

   function aaya_render(phrase, keywords) {
      const words = phrase.split(" ");
      var aaya = [];
      for (let j = 0; j < words.length; j++) {
         if (keywords.includes(words[j]))
            aaya.push(<span className='highlight1'>{words[j]}</span>, " ");
         else
            aaya.push(words[j], " ");
      }
      return aaya;
   }

   const head = ["no", "سورة", "آية", "نص"];
   const heads = [];
   for (let i = 0; i < head.length; i++) {
      heads.push(<th key={i}>{head[i]}</th>);
   }

   const k = [];  // object to array
   for (let i = 0; i < kalimat.length; i++) {
      k.push(kalimat[i][0]);
   }
   //console.log("kv", kv);

   const rows = [];
   for (let i = 0; i < ayat.length; i++) {
      const aaya = aaya_render(ayat[i].nass, k);
      var cols = []
      cols.push(<td key={0}>{ayat[i].id}</td>);
      cols.push(<td key={1}>{ayat[i].sura}</td>);
      cols.push(<td key={2}>{ayat[i].aaya}</td>);
      cols.push(<td key={3}>{aaya}</td>);

      rows.push(
         <tr key={i}
            onClick = {() => setSelectedRow(ayat[i].id)}
            onDoubleClick = {() => FindContext.handleClick}
            style={{ backgroundColor: selectedRow === ayat[i].id ? "#f0f0f0" : " #f0f0c8" }} >
            {cols}
         </tr>);
   }
   //console.log("rows", rows);

   return (
      <div className="col">
         <FindContext ayaNo={selectedRow} />
         <table>
            <thead><tr>{heads}</tr></thead>
            <tbody>{rows}</tbody>
         </table>
         {/* {selectedRow && <p>no: {selectedRow}</p>} */}
      </div>
   );
}

export function DisplayAyatBino({ ayat, klmt1, klmt2 }) {

   function aaya_render(phrase, keywords1, keywords2) {
      const words = phrase.split(" ");
      var aaya = [];
      for (let j = 0; j < words.length; j++) {
         if (keywords1.includes(words[j]))
            aaya.push(<span className='highlight1'>{words[j]}</span>, " ");
         else if (keywords2.includes(words[j]))
            aaya.push(<span className='highlight2'>{words[j]}</span>, " ");
         else
            aaya.push(words[j], " ");
      }
      return aaya;
   }

   const head = ["سورة", "آية", "نص"];
   const heads = [];
   for (let i = 0; i < head.length; i++) {
      heads.push(<th key={i}>{head[i]}</th>);
   }

   const kw1 = [];  // object to array
   for (let i = 0; i < klmt1.length; i++) {
      kw1.push(klmt1[i][0]);
   }
   //console.log("kv", kv);
   const kw2 = [];  // object to array
   for (let i = 0; i < klmt2.length; i++) {
      kw2.push(klmt2[i][0]);
   }
   //console.log("kv", kv);

   const rows = [];
   for (let i = 0; i < ayat.length; i++) {
      const aaya = aaya_render(ayat[i].nass, kw1, kw2);
      var cols = []
      cols.push(<td key={0}>{ayat[i].sura}</td>);
      cols.push(<td key={1}>{ayat[i].aaya}</td>);
      cols.push(<td key={2}>{aaya}</td>);
      rows.push(<tr key={i}>{cols}</tr>);
   }
   //console.log("rows", rows);

   return (
      <div>
         <table>
            <thead><tr>{heads}</tr></thead>
            <tbody>{rows}</tbody>
         </table>
      </div>
   );
}
