import React, { useEffect, useState } from "react";
//import React, { useState } from "react";
import GoBack from "../component/GoBack";
import { useLocation } from "react-router-dom";
import { request_search } from "../common/web";
import "../styles/mystyle.css";

function SearchContext() {

  useEffect(() => { GetContext1(); }, []);

  const location = useLocation();
  //const { userId, username } = location.state || {}; // Récupère userId et username
  const { ayaId } = location.state || {};
  const [ayat, ayat_set] = useState({});

  async function GetContext1() {
    console.log("GetContext1");
    //var ayat1, error;
    const [ayat1, error] = await request_search({ task: 401, id: ayaId, delta: 2 });
    if (error !== "") { alert(error); return; }
    ayat_set(ayat1);
  }

  function GetContext() {
    var ayat1, error;
    async function handleClick() {
      [ayat1, error] = await request_search({ task: 401, id: ayaId, delta: 2 });
      if (error !== "") { alert(error); return; }
      ayat_set(ayat1);
    }
    return (
      <div>
        <button onClick={handleClick} > get  context </button>
      </div>
    );
  };

  function DisplayContext() {
    const head = ["no", "سورة", "آية", "نص"];
    const heads = [];
    for (let i = 0; i < head.length; i++) {
      heads.push(<th key={i}>{head[i]}</th>);
    }

    const rows = [];
    for (let i = 0; i < ayat.length; i++) {
      var cols = []
      cols.push(<td key={0}>{ayat[i].id}</td>);
      cols.push(<td key={1}>{ayat[i].sura}</td>);
      cols.push(<td key={2}>{ayat[i].aaya}</td>);
      cols.push(<td key={3}>{ayat[i].nass}</td>);
      rows.push(<tr key={i}>{cols}</tr>);
    }
    //console.log("rows", rows);

    return (
      <div>
        <table>
          <thead><tr>{heads}</tr></thead>
          <tbody>{rows}</tbody>
        </table>
        {/* {selectedRow && <p>no: {selectedRow}</p>} */}
      </div>
    );
  }

  return (
    <div className="col" style={{ fontSize: 14 }}>
      <div className="row">
        <GoBack style={{ fontSize: "10px" }} />
        <label style={{ fontSize: "20px" }}> سياق </label>
      </div>
      {/* <p>aya id: {ayaId} </p> */}
      <GetContext />
      <DisplayContext />
    </div>
  );
}

export default SearchContext;
